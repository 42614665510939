import { getI18n } from 'react-i18next';
import { Workbox } from 'workbox-window';

const checkCanUseServiceWorker = () => {
  return 'serviceWorker' in navigator;
};

const register = async () => {
  if (!checkCanUseServiceWorker()) {
    return;
  }
  const wb = new Workbox('./service-worker.js');

  wb.addEventListener('activated', (event) => {
    getI18n().init();
  });

  return wb.register();
};

const unregister = async () => {
  if (!checkCanUseServiceWorker()) {
    return;
  }
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) {
    return;
  }
  await registration.unregister();
};

export default {
  register,
  unregister,
};
